<template>
  <span>
    <div class="col-12 mb-5 mb-lg-15">
      <label class="fw-bold fs-3">Biodata</label>
      <div class="separator border-gray-300 my-3"></div>
      <!--begin::detail item-->
      <div class="row" v-if="initialData && initialData.card_application">
        <label class="col-lg-3 form-label mb-0">No. KTA</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ initialData.card_application.kta_number }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Nama Lengkap</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.nama_lengkap }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Matra</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{
              personData && personData.dimension
                ? personData.dimension.name
                : "-"
            }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Pangkat / Korps</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData && personData.rank ? personData.rank.name : "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Kesatuan</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData && personData.unity ? personData.unity.name : "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
    </div>
    <div class="col-12 mb-5 mb-lg-15" v-if="personData">
      <label class="fw-bold fs-3">Sinyalemen</label>
      <div class="separator border-gray-300 my-3"></div>
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Tinggi Badan</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.tinggi ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Berat Badan</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.berat ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Rambut</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.rambut ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Mata</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.mata ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Golongan Darah</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.gol_darah ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Tempat Lahir</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.tempat_lahir ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Tanggal Lahir</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.tanggal_lahir ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Agama</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.agama ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Kontak Darurat</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.kontak_darurat ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
      <!--begin::detail item-->
      <div class="row">
        <label class="col-lg-3 form-label mb-0">Alamat</label>
        <div class="col-lg-9">
          <span class="fw-bold text-gray-800">
            {{ personData.alamat ?? "-" }}
          </span>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
    </div>
    <div class="col-12">
      <label class="fw-bold fs-3">Tanda Kehormatan</label>
      <div class="separator border-gray-300 my-3"></div>
      <!--begin::detail item-->
      <div class="row">
        <div class="col-12">
          <ol class="fw-bold text-gray-800 mb-0">
            <li v-for="(honor, index) in personData.honor" :key="index">
              {{ honor.name }}
            </li>
          </ol>
        </div>
      </div>
      <div class="separator separator-dashed border-gray-300 my-3"></div>
      <!--end::detail item-->
    </div>
  </span>
</template>
<script>
import { toRaw } from "vue";

export default {
  props: ["initial"],
  data() {
    return {
      initialData: {},
      personData: {},
    };
  },
  watch: {
    initial(value) {
      this.initialData = toRaw(value);
      this.personData = this.initialData.card_application.person;
    },
  },
  mounted() {},
};
</script>
