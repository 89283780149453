<template>
  <div class="col-xxl-12 mb-5 mb-xl-10">
    <div class="row">
      <div class="col-12">
        <div class="card mb-5 mb-xl-8 h-xl-100">
          <!--begin::Card body-->
          <div class="card-body">
            <center v-if="loading">
              <img
                src="/loading.gif"
                alt=""
                srcset=""
                style="max-width: 300px"
              />
            </center>
            <div class="row" v-else>
              <div class="col-md-3 pe-md-10 mb-10 mb-md-0">
                <kta-preview :src="imageKta" />
              </div>
              <div class="col-md-9">
                <status-kta :status="status" />
                <kta-detail
                  v-if="serial && status != 'block'"
                  :serial="serial"
                />

                <div
                  class="fs-5 fw-semibold text-gray-600"
                  v-if="status != 'block'"
                >
                  <div class="mb-10" v-if="validateData">
                    <div class="d-flex flex-stack mb-3">
                      <h3 class="text-dark fs-2">Info Pengesahan Digital</h3>
                    </div>
                    <div class="separator separator-dashed mb-5"></div>
                    <div class="row g-10">
                      <div class="col-md-4">
                        <div
                          class="d-flex flex-column mb-3 justify-content-center"
                        >
                          <div class="fs-4 fw-bold text-gray-500">
                            Tempat, Tanggal Pengesahan
                          </div>
                          <div class="fs-4 fw-semibold text-gray-800">
                            [ {{ validateData.validation_at }},
                            {{ validateData.validation_date }} ]
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div
                          class="d-flex flex-column mb-3 justify-content-center"
                        >
                          <div class="fs-4 fw-bold text-gray-500">
                            Disahkan oleh
                          </div>
                          <div class="fs-4 fw-semibold text-gray-800">
                            [ {{ validateData.validation_by.name }} ]
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div
                          class="d-flex flex-column mb-3 justify-content-center"
                        >
                          <div class="fs-4 fw-bold text-gray-500">Jabatan</div>
                          <div class="fs-4 fw-semibold text-gray-800">
                            [ {{ validateData.validation_by.position }} ]
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="separator separator-dashed my-5"></div>
                  </div>
                </div>

                <div
                  class="notice d-flex bg-light-primary rounded border-primary border p-6"
                >
                  <!--begin::Wrapper-->
                  <div class="d-flex align-items-center">
                    <img
                      src="/assets/media/logos/logo-bsre.png"
                      class="h-50px me-3"
                    />

                    <div class="d-flex justify-content-start flex-column">
                      <span class="text-gray-800 fw-bold mb-1 fs-6">
                        Pengesahan KTP tersebut telah ditandatangani secara
                        elektronik menggunakan sertifikat elektronik yang
                        diterbitkan oleh Balai Sertifikasi Elektronik (BSrE),
                        BSSN.
                      </span>
                    </div>
                  </div>
                  <!--end::Wrapper-->
                </div>
              </div>
            </div>
            <!--end::Details content-->
          </div>
          <!--end::Card body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KtaPreview from "@/components/kta/KtaPreview.vue";
import KtaDetail from "@/components/kta/KtaDetail.vue";
import StatusKta from "@/components/kta/StatusKta.vue";

export default {
  components: {
    KtaPreview,
    StatusKta,
    KtaDetail,
  },
  data() {
    return {
      status: "",
      serial: "",
      validateData: null,
      imageKta: null,
      loading: true,
    };
  },
  mounted() {
    this.getData();
    const { status, serial } = this.$route.params;
    this.status = status;
    this.serial = serial;
  },
  methods: {
    async getData() {
      this.loading = true;

      try {
        let nrp = "";
        let unique = "";
        Object.entries(this.$route.query).forEach(([key, value]) => {
          nrp = key;
          unique = value;
        });
        const baseApiUrl = process.env.VUE_APP_BASE_API_URL;
        const { data } = await fetch(
          `${baseApiUrl}external/qrcode-verify/${unique}?nrp=${nrp}`
        ).then((result) => {
          return result.json();
        });

        this.status = data.card_status;
        this.validateData = data;
        if (data.images) {
          this.imageKta = `data:image/png;base64, ${data.images}`;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
