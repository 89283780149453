<template>
  <span>
    <!-- /assets/media/illustrations/kta-portrait-front.png -->
    <img :src="src ? src : '/no-image.png'" class="w-100" />
  </span>
</template>
<script>
export default {
  props: ["src"],
};
</script>
