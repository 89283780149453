<template>
  <span>
    <span v-if="status === 'valid'">
      <div class="text-center">
        <img src="/assets/media/illustrations/check-1.png" class="w-150px" />
      </div>
      <div class="mb-10 mt-5">
        <div
          class="d-flex flex-column align-items-center mb-3 justify-content-center"
        >
          <span
            class="badge badge-light-success fs-2x fs-1x fw-bold px-5 py-3 rounded-pill text-center"
          >
            Valid dan Aktif.
          </span>
        </div>
      </div>
    </span>
    <span v-if="status === 'lost'">
      <div class="text-center">
        <img src="/assets/media/illustrations/warning-1.png" class="w-150px" />
      </div>
      <div class="mb-10 mt-5">
        <div
          class="d-flex flex-column align-items-center mb-3 justify-content-center"
        >
          <span
            class="badge badge-light-warning fs-2x fs-1x fw-bold px-5 py-3 rounded-pill text-center"
          >
            Valid dan Aktif.
          </span>
        </div>
      </div>
      <div class="mb-10 mt-5">
        <!--begin::Alert-->
        <div
          class="alert bg-warning d-flex align-items-center p-5 border-warning"
        >
          <!--begin::Icon-->
          <i class="ki-duotone ki-information fs-2hx text-white me-4">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </i>
          <!--end::Icon-->

          <!--begin::Wrapper-->
          <div class="d-flex flex-column">
            <!--begin::Title-->
            <h4 class="mb-1 text-white fs-2x">Perhatian</h4>
            <!--end::Title-->

            <!--begin::Content-->
            <span class="text-white fs-4">
              KTP ini telah dilaporkan <b>hilang</b>.<br />
              Jika menemukan KTP ini, harap segera diserahkan ke Kesatuan TNI
              terdekat atau hubungi kontak darurat yang tertera pada bagian
              belakang KTP.</span
            >
            <!--end::Content-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Alert-->
      </div>
    </span>
    <span v-if="status === 'block'">
      <div class="text-center">
        <img src="/assets/media/illustrations/cross-1.png" class="w-150px" />
      </div>
      <div class="mb-10 mt-5">
        <div
          class="d-flex flex-column align-items-center mb-3 justify-content-center"
        >
          <span
            class="badge badge-light-danger fs-2x fs-1x fw-bold px-5 py-3 rounded-pill text-center"
          >
            KTP Diblokir
          </span>
        </div>
      </div>
      <div class="mb-10 mt-5">
        <!--begin::Alert-->
        <div
          class="alert bg-danger d-flex align-items-center p-5 border-danger"
        >
          <!--begin::Icon-->
          <i class="ki-duotone ki-information fs-2hx text-white me-4">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </i>
          <!--end::Icon-->

          <!--begin::Wrapper-->
          <div class="d-flex flex-column">
            <!--begin::Title-->
            <h4 class="mb-1 text-white fs-2x">Perhatian</h4>
            <!--end::Title-->

            <!--begin::Content-->
            <span class="text-white fs-4">
              Kartu Anggota TNI Anda telah <b>diblokir</b>.<br />
              Segala kewenangan yang melekat di KTP sudah tidak dapat
              difungsikan di dalam lingkup TNI.</span
            >
            <!--end::Content-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Alert-->
      </div>
    </span>
  </span>
</template>

<script>
export default {
  props: ["status"],
};
</script>
